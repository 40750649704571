import React, { Component } from "react";
import { Translation } from "react-i18next";

class InfoCard extends Component {
  render() {
    return (
      <div className="info-card">
        <Translation>
          {(t, { i18n }) => (
            <React.Fragment>
              <div className="info-item">
                <p className="input-label">{t("l-f-name")}</p>
                <p className="info-text">{this.props.fullName}</p>
              </div>
              <div className="info-item">
                <p className="input-label">{t("adr")}</p>
                <p className="info-text">{this.props.adresse}</p>
              </div>
              <div className="info-item">
                <p className="input-label">{t("attached-file")}</p>
                {this.props.files.map((file, key) => {
                  return (
                    <p key={key} className="info-text">
                      • {file.path}
                    </p>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </Translation>
      </div>
    );
  }
}

export default InfoCard;
