import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../../redux/actions/actions";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";
import { SIZE as btn_size } from "baseui/button";
import { Translation } from 'react-i18next';


class ConfirmDeleteModal extends Component {
    render() {
        return (
            <Modal
                onClose={() => {
                    this.props.closeModal(false);
                }}
                overrides={{
                    Dialog: {
                        style: {
                            borderRadius: "6px"
                        }
                    }
                }}
                closeable
                isOpen={this.props.c_delete}
                animate
                autoFocus
                size={SIZE.default}
            >
                <Translation>
                    {(t,{i18n}) =>(
                    <React.Fragment>
                        <ModalHeader>{t('modal-title')}</ModalHeader>
                        <ModalBody>
                            {t('modal-msg')}
                        </ModalBody>
                        <ModalFooter>
                            <div className="custom-modal-footer">
                                <ModalButton
                                    onClick={this.props.closeModal}
                                    size={btn_size.compact}
                                    overrides={{
                                        BaseButton: {
                                            style: {
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                                color:"#376eaf",
                                                ":hover":{
                                                    backgroundColor:'white'
                                                }
                                            }
                                        }
                                    }}
                                >
                                    {t('modal-cancel')}
                                </ModalButton>
                                <ModalButton
                                    onClick={this.props.onConfirm}
                                    size={btn_size.compact}
                                    overrides={{
                                        BaseButton: {
                                            style: {
                                                borderRadius: "5px",
                                                backgroundColor: "#376eaf"
                                            }
                                        }
                                    }}
                                >
                                    {t('modal-confirm')}
                                </ModalButton>
                            </div>
                        </ModalFooter>
                    </React.Fragment>
                        )}
                </Translation>
            </Modal>
        );
    }
}

const mapStateToProps = ({ main }) => {
    return {
        c_delete: main.c_delete
    };
};
const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => {
            dispatch({ type: actions.SET_STATE, payload: { c_delete: false } });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteModal);
