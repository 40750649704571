import React from 'react';
import logo from '../../asset/logo-white.svg'
import map from '../../asset/footer-map.jpg'

import { useTranslation } from 'react-i18next';

export default function Footer (){
    console.log('render footer')
    const { t } = useTranslation(['translation']);

    return (
        <footer className="footer footer--main">
                <div className="container">
                    <div className="footer-row">
                        <div className="col-logo text-center text-md-left">
                            <img src={logo} alt="" className="footer__logo" />
                        </div>
                        <div className="row-content">
                            <div className="col-hours text-center text-md-left">
                                <div className="footer__info footer__info-border">
                                    <div className="footer__info mb-2">
                                        <h4>{t('footer.irm.label')}</h4>
                                        <p>{t('footer.irm.slot1')}</p>
										{/*<p>{t('footer.irm.slot2')}</p>*/}
                                    </div>
                                    <div className="footer__info mb-2">
                                        <h4>{t('footer.scanner.label')}</h4>
                                        <p>{t('footer.scanner.slot1')}</p>
                                    </div>
                                    <div className="footer__info mb-2">
                                        <h4>{t('footer.radio-standard.label')}</h4>
                                        <p>{t('footer.radio-standard.slot1')}</p>
                                    </div>
                                    <div className="footer__info mb-2">
                                        <h4>{t('footer.img-femme.label')}</h4>
                                        <p>{t('footer.img-femme.slot1')}</p>
                                    </div>
                                    <div className="footer__info mb-2">
                                        <h4>{t('footer.med-nucleaire.label')}</h4>
                                        <p>{t('footer.med-nucleaire.slot1')}</p>
                                        {/*<p>{t('footer.med-nucleaire.slot2')}</p>*/}
                                    </div>
                                    <h4>{t('footer.retrait.label')}</h4>
                                    <p>
                                        {t('footer.retrait.slot1')}
                                    </p>
                                    <p>
                                        {t('footer.retrait.slot2')}
                                    </p>
                                </div>
                            </div>
                            <div className="col-info mb-4 text-center text-md-left">

                                <h4>{t('footer.contact-us')}</h4>
                                <a href="tel:021323782828" className="footer__cta" >
                                    {t('footer.tel')}
                                </a>
                                <a href="tel:021323782828" className="footer__cta">
                                    {t('footer.fax')}
                                </a>
                                <a href="mailto:contact@cima.dz" className="footer__cta">
                                    {t('footer.email')}
                                </a>
                                <div className="footer__social text-center text-md-left">
                                    <p>
                                        {t('footer.follow-us')}
                                    </p>
                                    <a href="#0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                id="facebook"
                                                d="M24,12A12,12,0,1,0,12,24l.211,0V14.656H9.633v-3h2.578V9.44a3.609,3.609,0,0,1,3.853-3.96,21.223,21.223,0,0,1,2.311.118v2.68H16.8c-1.244,0-1.485.591-1.485,1.459v1.914h2.975l-.388,3H15.312v8.881A12.006,12.006,0,0,0,24,12Z"
                                                fill="#fff"
                                            />
                                        </svg>
                                    </a>
                                </div>

                            </div>
                            <div className="col-map text-md-left text-center">
                                <a
                                    href="https://www.google.com/maps/place/Centre+d'Imagerie+Yaker+-CIMA/@36.730948,3.088095,16z"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="footer-map-link"
                                >
                                    <img src= {map} alt="" className="footer-map-img lazyload" />
                                </a>
                                <div className="footer__info-map">
                                    <p> {t('footer.address')} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* )} */}
            {/* </Translation> */}
        </footer>
    );
}