import React, { Component } from "react";
import SubmitBtn from "../firstStep/SubmitBtn";
import { connect } from "react-redux";
import actions from "../../../redux/actions/actions";
import CustomPinCode from "./CustomPinCode";
import { Translation } from "react-i18next";

import { firebaseAppAuth, recaptchaVerifier } from "../../../firebase";

class FirstStepConfirm extends Component {
  constructor() {
    super();
    this.state = {
      confirmResult: null,
      error: null,
    };
  }

  componentWillMount() {
    this.setState({
      confirmResult: this.props.confirmResult,
      error: null,
    });
  }

  componentDidMount() {
    window.recaptchaVerifier = recaptchaVerifier(this.recaptcha);
  }

  render() {
    return (
      <div className="first-step-container">
        <div ref={(ref) => (this.recaptcha = ref)}></div>
        <Translation>
          {(t, { i18n }) => (
            <React.Fragment>
              <div className="row">
                <div className="row-item-equal">
                  <p>
                    {t("code-sent")}{" "}
                    <span className="phone-text">{this.props.phoneNumber}</span>
                    <span
                      className="edit-phone"
                      onClick={() => this.props.toPreviousStep()}
                    >
                      {" "}
                      , {t("edit-num")}
                    </span>
                  </p>
                  <p>{t("introduce-code")}</p>
                </div>
              </div>
              <div className="row">
                <div className="row-item-equal pin-input-container">
                  <CustomPinCode
                    values={this.props.code}
                    label={t("6-degit")}
                    onChange={({ values }) => {
                      this.props.updateCode(values);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                {this.state.error ? (
                  <div className="err-msg" style={{ marginTop: 8 }}>
                    {this.state.error.code === "auth/invalid-verification-code"
                      ? t("invalid-code")
                      : t("many-attempts")}
                  </div>
                ) : (
                  <div
                    className="row-item-equal input-container center-btn rtl-center"
                    onClick={() => {
                      const { phoneNumber } = this.props;
                      firebaseAppAuth
                        .signInWithPhoneNumber(
                          phoneNumber,
                          window.recaptchaVerifier
                        )
                        .then((confirmResult) => {
                          this.setState({
                            confirmResult,
                            error: null,
                          });
                        })
                        .catch((error) => {
                          this.setState({
                            error: error,
                          });
                          console.log(error);
                        });
                    }}
                  >
                    {t("resend-code")}
                  </div>
                )}
              </div>

              <SubmitBtn
                onClick={() => {
                  this.state.confirmResult
                    .confirm(this.props.code.join(""))
                    .then((result) => {
                      this.props.toNextStep();
                    })
                    .catch((error) => {
                      this.setState({
                        error: error,
                      });
                      console.log(error);
                    });
                }}
              />
            </React.Fragment>
          )}
        </Translation>
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  return {
    code: main.code,
    confirmResult: main.confirmResult,
    phoneNumber: main.phoneNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCode: (code) => {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          code,
        },
      });
    },
    toNextStep: () => {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          step: 2,
        },
      });
    },
    toPreviousStep: () => {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          step: 0,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstStepConfirm);
