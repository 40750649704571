import React, {Component} from 'react';
import {Button,SIZE} from 'baseui/button';
import ArrowLeft from 'baseui/icon/arrow-left';
import ArrowRight from 'baseui/icon/arrow-right';
import i18n from "i18next";

import { ARABIC} from '../../pageHeader/HeaderLng'

class SubmitAppointment extends Component {
    render() {
        return (
                <Button
                    isLoading={this.props.isLoading}
                    size={SIZE.compact}
                    endEnhancer={() => i18n.language!==ARABIC? <ArrowRight size={24}/>: <ArrowLeft size={24}/>}
                    onClick={this.props.onClick}
                    overrides={{
                    BaseButton: {
                        style: {
                            borderRadius: "5px",
                            backgroundColor:"#376eaf",
                            borderStyle:"solid",
                            borderWidth:"1px",
                            borderColor:"#376eaf",                        }
                    }
                }}>
                {this.props.text}
                </Button>
        );
    }
}

export default SubmitAppointment;