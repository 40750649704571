import React, { Component } from "react";
import { TimePicker } from "baseui/timepicker";
import { FormControl } from "baseui/form-control";

class CustomSelect extends Component {
  render() {
    return (
      <FormControl
        label={<p className="input-label">{this.props.label.toUpperCase()}</p>}
      >
        <div className="custom-time-picker w-100">
          <TimePicker
            disabled={this.props.disabled ? true : false}
            value={this.props.value}
            overrides={{
              ControlContainer: {
                style: {
                  borderRadius: "5px",
                  borderWidth: "1.5px",
                  borderColor: "#eef2f7",
                  backgroundColor: "#f7f9fc",
                  height: "37px",
                },
              },
              ValueContainer: {
                style: {
                  height: "40px",
                  marginTop: "-5px",
                },
              },
            }}
            options={this.props.options}
            onChange={this.props.onChange}
            format={24}
          />
        </div>
      </FormControl>
    );
  }
}

export default CustomSelect;
