import React from 'react'
import {Switch , Route, Redirect} from 'react-router-dom'
import PageContent from '../components/PageContent/PageContent'
import Login from '../components/login/Login'
import Reservation from '../components/Reservartion/Reservartion'
import Verification from '../components/verification/Verification'

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
  </div>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem('token')
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )

export default function Routes() {
    return(
        <Switch>
            <Route path='/' exact component={PageContent}/>
            <Route path='/login' exact component={Login}/>
            <Route path='/confirmPhoneNumber' exact component={Verification}/>
            <PrivateRoute path='/reservation/:id' exact component={Reservation}/>
            <Route component={NotFound}></Route>
        </Switch>
    )
}