import React, { Component } from "react";
import CustomInput from "../PageContent/CustomInput";
import SubmitBtn from "../PageContent/firstStep/SubmitBtn";
import CustomPinCode from "../PageContent/firstStepConfirm/CustomPinCode";

import { connect } from "react-redux";
import actions from "../../redux/actions/actions";
import withFirebaseAuth from "react-with-firebase-auth";
import * as firebase from "firebase";
import * as Yup from "yup";
import config from "../../firebase/config";
import { Formik } from "formik";
import queryString from "query-string";
import history from "../../services/history";

import {Translation} from 'react-i18next';

class Verification extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            confirmResult: null,
            reservationId: "",
            phoneNumber: "",
            code: ["", "", "", "", "", ""]
        };
    }
    componentDidMount() {
        const {confirmResult} = this.props
        console.log(confirmResult)
        this.setState({
            loading: false,
            confirmResult
        });
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            this.recaptcha,
            {
                size: "invisible"
            }
        );
    }
    render() {
        const { signInWithPhoneNumber } = this.props;
        return (
            <div className="page-content-container">
                <Translation>
                {(t, {i18n}) => 
                    <div className="main-card">
                        <div className="card-header-content">
                            <h2>{t("login")}</h2>
                        </div>
                        <hr />
                        
                            <React.Fragment>
                                <div ref={ref => (this.recaptcha = ref)}></div>
                                <div className="first-step-container">
                                    <div className="row">
                                        <div className="row-item-equal">
                                            <p>
                                                {t('code-sent')}{" "}
                                                <span className="phone-text">
                                                    {this.props.phoneNumber}
                                                </span>
                                                <span
                                                    className="edit-phone"
                                                    onClick={() =>
                                                        history.push('/login')
                                                    }
                                                >
                                                    {" "}
                                                    , {t('edit-num')}
                                                </span>
                                                <p>{t('introduce-code')}</p>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="row-item-equal pin-input-container ">
                                            <CustomPinCode
                                                values={this.state.code}
                                                label={t('6-degit')}
                                                onChange={({ values }) => {
                                                    this.setState({ code: values });
                                                }}
                                            />
                                            {/*<CustomInput label="6-Degit" onChange={(event) => {this.props.updateCode(event.target.value)}} value={this.props.code}/>*/}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div
                                            className="row-item-equal input-container center-btn rtl-center"
                                            onClick={() => {
                                                const { phoneNumber } = this.props;
                                                signInWithPhoneNumber(
                                                    phoneNumber,
                                                    window.recaptchaVerifier
                                                )
                                                    .then(confirmResult => {
                                                        this.setState({
                                                            confirmResult
                                                        });
                                                    })
                                                    .catch(error => {
                                                        console.log("error", error);
                                                    });
                                            }}
                                        >
                                            {t('resend-code')}
                                        </div>
                                    </div>
                                    <SubmitBtn
                                        onClick={() => {
                                            this.state.confirmResult
                                                .confirm(this.state.code.join(""))
                                                .then(result => {
                                                    this.props.history.push(`/reservation/${this.props.reservationId}`)
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        
                    </div>
                }
                </Translation>
            </div>
        );
    }
}

const firebaseAppAuth = firebase.auth();
const mapStateToProps = state => {
    return {
        email: state.main.email,
        reservationId: state.main.reservationId,
        phoneNumber: state.main.phoneNumber,
        confirmResult: state.main.confirmResult
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateState: payload => {
            dispatch({ type: actions.SET_STATE, payload: payload });
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withFirebaseAuth({ firebaseAppAuth })(Verification));
