import React, { Component } from "react";
import CustomInput from "../CustomInput";
import CustomSelect from "../CustomSelect";
import FileUpload from "./FileUpload";
import SubmitBtn from "../firstStep/SubmitBtn";
import { connect } from "react-redux";
import actions from "../../../redux/actions/actions";
import * as Yup from "yup";
import { Formik } from "formik";
import { Translation } from "react-i18next";
import { deviceIsMobile } from "../../../services/utils";

class SecondStep extends Component {
  constructor() {
    super();
    this.state = {
      wilaya_err: null,
      commune_err: null,
      file_err: null,
    };
  }
  componentWillMount() {
    this.props.getWilayas();
  }

  render() {
    return (
      <div
        className="second-step-container"
        style={{ width: deviceIsMobile() ? "90%" : undefined }}
      >
        <Translation>
          {(t, { i18n }) => (
            <React.Fragment>
              <Formik
                initialValues={{
                  nom: this.props.nom,
                  prenom: this.props.prenom,
                  wilaya: this.props.wilaya,
                  commune: this.props.commune,
                }}
                validationSchema={Yup.object({
                  nom: Yup.string().required(t("input-required")),
                  prenom: Yup.string().required(t("input-required")),
                })}
                onSubmit={(values, { SetSubmiting }) => {
                  if (
                    this.props.wilaya.id === null ||
                    this.props.commune.id === null ||
                    this.props.selectedFile.length === 0
                  ) {
                    if (this.props.wilaya.id === null)
                      this.setState({ wilaya_err: true });
                    if (this.props.commune.id === null)
                      this.setState({ commune_err: true });
                    if (this.props.selectedFile.length === 0)
                      this.setState({ file_err: true });
                  } else {
                    this.setState({
                      loading: true,
                    });
                    const { nom, prenom } = values;
                    this.props.updateState({ nom, prenom, step: 3 });
                  }
                }}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <div
                      className={`${
                        deviceIsMobile() ? "" : "row"
                      } rtl-row-reverse`}
                    >
                      <div className="row-item-equal input-container">
                        <CustomInput
                          label={t("l-name-label")}
                          placeholder={t("l-name-placeholder")}
                          id="nom"
                          {...formik.getFieldProps("nom")}
                        />
                        {formik.touched.nom && formik.errors.nom ? (
                          <div className="err-msg">{formik.errors.nom}</div>
                        ) : null}
                      </div>
                      <div className="row-item-equal input-container">
                        <CustomInput
                          label={t("f-name-label")}
                          placeholder={t("f-name-placeholder")}
                          id="prenom"
                          {...formik.getFieldProps("prenom")}
                        />
                        {formik.touched.prenom && formik.errors.prenom ? (
                          <div className="err-msg">{formik.errors.prenom}</div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={`${
                        deviceIsMobile() ? "" : "row"
                      } rtl-row-reverse`}
                    >
                      <div className="row-item-equal input-container custom-select">
                        <CustomSelect
                          label={t("wilaya-label")}
                          value={this.props.wilaya}
                          placeholder={t("select-place-holder")}
                          onChange={(event) => {
                            this.setState({ wilaya_err: null });
                            this.props.updateWilaya(event.option);
                            this.props.updateCommune({ id: null, label: null });
                            const communes = event.option.communes;
                            const allCommunes = [];
                            communes.map((commune) => {
                              allCommunes.push({
                                label: commune.nom,
                                id: commune.commune_id,
                              });
                            });
                            this.props.updateAllCommunes(allCommunes);
                          }}
                          options={this.props.allWilayas}
                        />
                        {this.state.wilaya_err ? (
                          <div className="err-msg">{t("input-required")}</div>
                        ) : null}
                      </div>
                      <div className="row-item-equal input-container custom-select">
                        <CustomSelect
                          label={t("commune-label")}
                          value={this.props.commune}
                          placeholder={t("select-place-holder")}
                          onChange={(event) => {
                            this.setState({ commune_err: null });
                            this.props.updateCommune(event.option);
                          }}
                          options={this.props.allCommunes}
                        />
                        {this.state.commune_err ? (
                          <div className="err-msg">{t("input-required")}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="row-item-equal"
                        style={{ maxWidth: "100%" }}
                      >
                        <FileUpload
                          label={t("upload-file")}
                          selectedFile={this.props.selectedFile}
                          onDrop={(event) => {
                            this.setState({ file_err: null });
                            event.length &&
                              this.props.updateSelectedFile([
                                ...this.props.selectedFile,
                                ...event,
                              ]);
                          }}
                          removeFile={(index) => {
                            const newSelected = [...this.props.selectedFile];
                            newSelected.splice(index, 1);
                            this.props.updateSelectedFile(newSelected);
                          }}
                        />                        
                        {this.state.file_err && (
                          <div className="err-msg">{t("input-required")}</div>
                        )}
                      </div>
                    </div>
                    <SubmitBtn onClick={formik.handleSubmit} />
                  </form>
                )}
              </Formik>
            </React.Fragment>
          )}
        </Translation>
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  return {
    nom: main.nom,
    prenom: main.prenom,
    wilaya: main.wilaya,
    commune: main.commune,
    typeExamen: main.typeExamen,
    allWilayas: main.allWilayas,
    allCommunes: main.allCommunes,
    selectedFile: main.selectedFile,
    error: main.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateNom: (nom) => {
      dispatch({ type: actions.SET_STATE, payload: { nom } });
    },
    updatePrenom: (prenom) => {
      dispatch({ type: actions.SET_STATE, payload: { prenom } });
    },
    updateWilaya: (wilaya) => {
      dispatch({ type: actions.SET_STATE, payload: { wilaya } });
    },
    updateCommune: (commune) => {
      dispatch({ type: actions.SET_STATE, payload: { commune } });
    },
    updateTypeExamen: (typeExamen) => {
      dispatch({ type: actions.SET_STATE, payload: { typeExamen } });
    },
    toNextStep: () => {
      dispatch({ type: actions.SET_STATE, payload: { step: 3 } });
    },
    getWilayas: () => {
      dispatch({ type: actions.GET_WILAYAS });
    },
    updateAllCommunes: (allCommunes) => {
      dispatch({ type: actions.SET_STATE, payload: { allCommunes } });
    },
    updateSelectedFile: (selectedFile) => {
      dispatch({ type: actions.SET_STATE, payload: { selectedFile } });
    },
    updateState: (payload) => {
      dispatch({ type: actions.SET_STATE, payload: payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
