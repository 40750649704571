import React, { Component } from 'react';
import ConfirmationCard from './ConfirmationCard';
import CustomBtnOutline from './CustomBtnOutline';
import {FiEdit} from 'react-icons/fi';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { connect } from 'react-redux';
import actions from '../../../redux/actions/actions';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { Translation } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import NextBtn from "./NextBtn";
import { deviceIsMobile } from "../../../services/utils";

class FourthStep extends Component {
    componentDidMount() {
        hotjar.initialize(1801087, 6);
    }

    render() {
        return (
            <div className="fourth-step-container" style={{ width: deviceIsMobile() ? "90%" : undefined }}>
                <Translation>
                    {(t,{i18n})=>(
                    <div className="row">
                        <div className="row-item-equal">
                            <ConfirmationCard id={this.props.reservationId} fullName={`${this.props.nom} ${this.props.prenom}`} adresse={`${this.props.wilaya.label} , ${this.props.commune.label}`} filename={this.props.selectedFile.path}/>
                            <div className="c-btn-row">
                                <div className="other-btn">
                                    <div className="submit-btn-container-final">
                                        <CustomBtnOutline onClick={this.props.openModal} icon={<AiOutlineCloseCircle size={24}/>} value={t('cancel-appointmetn')} />
                                    </div>
                                    <div className="submit-btn-container-final">
                                        <CustomBtnOutline onClick={this.props.editAppointment} icon={<FiEdit size={24}/>} value={t('edit-appointement')}/>   
                                    </div>
                                </div>
                                <div className="finish-btn submit-btn-container-final">
                                    <NextBtn onClick={this.props.toNextStep}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </Translation>
                                
                <ConfirmDeleteModal onConfirm={() =>{
                    this.props.cancelAppointment(this.props.reservationId)
                }} />
            </div>
        );
    }
}

const mapStateToProps = ({main}) =>{
    return{
        reservationId : main.reservationId,
        nom:main.nom,
        prenom:main.prenom,
        wilaya : main.wilaya,
        commune : main.commune,
        /*typeExamen : main.typeExamen,*/
        selectedFile : main.selectedFile
    }
}

const mapDispatchToProps = (dispatch =>{
    return{
        openModal : () =>{dispatch({type:actions.SET_STATE,payload:{c_delete : true}})},
        cancelAppointment : (reservationId) =>{dispatch({type:actions.DELETE_APPOINTMENT,payload:{reservationId}})},
        editAppointment : ()=>{dispatch({type:actions.SET_STATE,payload:{step:3,edit:true, fromLogin:false}})},
        toNextStep : ()=>{dispatch({type:actions.SET_STATE,payload:{step:5}})},
    }
})

export default connect(mapStateToProps,mapDispatchToProps)(FourthStep);