import React, { Component } from 'react';
import PageLogo from './PageLogo';
import HeaderContentPhone from './HeaderContentPhone';
import HeaderLng from './HeaderLng';

class PageHeader extends Component {
    render() {
        return (
            <div className="page-header-container">
                <div className="header-logo">
                    <PageLogo />
                </div>
                {/* <div className="page-header-content"> */}
                    <div className="header-content">
                        <HeaderContentPhone />
                    </div>
                    <div className="header-lng">
                        <HeaderLng />
                    </div>
                {/* </div> */}
            </div>
        );
    }
}

export default PageHeader;