import React, {Component} from 'react';
import {Button,SIZE} from 'baseui/button';

class CustomBtnOutline extends Component {
    render() {
        return (
                <Button
                    type="submit"
                    size={SIZE.compact}
                    endEnhancer={() => this.props.icon}
                    onClick={this.props.onClick}
                    overrides={{
                    BaseButton: {
                        style: {
                            borderRadius: "5px",
                            backgroundColor:"white",
                            color : "#376eaf",
                            borderStyle:"solid",
                            borderWidth:"1px",
                            borderColor:"#376eaf",
                            ':hover':{
                                color:'white',
                                backgroundColor:'#376eaf'
                            }
                        }
                    }
                }}>
                    {this.props.value}
                </Button>
        );
    }
}

export default CustomBtnOutline;