import React, { Component } from "react";
import { connect } from "react-redux";
import DatesSlots from "./DatesSlots";
import { Translation } from "react-i18next";

class ConfirmationCard extends Component {
  render() {
    return (
      <Translation>
        {(t, { i18n }) => (
          <React.Fragment>
            <div className="confirmation-card">
              <div className="info-item">
                <p className="input-label">{t("l-f-name")}</p>
                <p className="info-text">{this.props.fullName}</p>
              </div>
              <div className="info-item">
                <p className="input-label">{t("adr")}</p>
                <p className="info-text">{this.props.adresse}</p>
              </div>
              <div className="info-item">
                <p className="input-label">{t("attached-file")}</p>
                <p className="info-text">{this.props.filename}</p>
              </div>
              <div className="info-item">
                <p className="input-label">{t("res-id")}</p>
                <p className="info-text">{this.props.id}</p>
              </div>
              <h3 className="c-date-title">{t("appointments")}</h3>
              <DatesSlots />
            </div>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

const mapStatetoProps = ({ main }) => {
  return {
    dates: main.dates,
  };
};

const mapDisptachToProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDisptachToProps)(ConfirmationCard);
