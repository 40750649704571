import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Display, Paragraph4 } from "baseui/typography";
const CardHeader = (props) => {
  const { t } = useTranslation("translation");
  return (
    <>
      <div className="card-header-content">
        {props.step === 0 ? <h2>{t("card-title-zero")}</h2> : null}
        {props.step === 1 ? <h2>{t("card-title-one")}</h2> : null}
        {props.step === 2 ? <h2>{t("card-title-two")}</h2> : null}
        {props.step === 3 ? <h2>{t("card-title-three")}</h2> : null}
        {props.step === 4 ? <h2>{t("card-title-four")}</h2> : null}
        {props.step === 5 ? <h2>{t("card-title-four")}</h2> : null}
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 10,
          textAlign: "center",
        }}
      >
        <Paragraph4>{t("sticky-msg")}</Paragraph4>
      </div>
    </>
  );
};

const mapStateToProps = ({ main }) => {
  return {
    step: main.step,
  };
};

export default connect(mapStateToProps)(CardHeader);
