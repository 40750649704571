import config from 'dotenv'
config.config()
export default {
    websiteURL: process.env.REACT_APP_WEBSITE_URL,
    fireBase: {
        apiKey: process.env.REACT_APP_FIRBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIRBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIRBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIRBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIRBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIRBASE_MESSAGE_SENDER_ID,
        appId: process.env.REACT_APP_FIRBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIRBASE_MEASUREMENT_ID
    }
}