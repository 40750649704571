import React, { Component } from "react";
import { connect } from "react-redux";

import * as firebase from "firebase";
import withFirebaseAuth from "react-with-firebase-auth";

import { Translation } from "react-i18next";

import { toast } from "react-toastify";

import { Formik } from "formik";
import * as Yup from "yup";

import queryString from "query-string";

import CustomInput from "../PageContent/CustomInput";
import SubmitBtn from "../PageContent/firstStep/SubmitBtn";

import actions from "../../redux/actions/actions";
import history from "../../services/history";

import { contryCode } from "../../firebase/index";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      confirmResult: null,
      reservationId: "",
      phoneNumber: "",
      code: ["", "", "", "", "", ""],
    };
  }
  componentDidMount() {
    const { reservationId } = queryString.parse(window.location.search);
    this.setState({ reservationId });
    this.setState({
      loading: false,
    });
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      this.recaptcha,
      {
        size: "invisible",
      }
    );
  }
  render() {
    const { signInWithPhoneNumber } = this.props;
    return (
      <div className="page-content-container">
        <div className="main-card">
          <div className="card-header-content">
            <Translation>{(t, { i18n }) => <h2>{t("login")}</h2>}</Translation>
          </div>
          <hr />
          <React.Fragment>
            <div ref={(ref) => (this.recaptcha = ref)}></div>
            <div className="first-step-container">
              <Translation>
                {(t, { i18n }) => (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      reservationId: this.state.reservationId,
                      phoneNumber: this.state.phoneNumber,
                    }}
                    validationSchema={Yup.object({
                      reservationId: Yup.string().required(
                        t("code-rdv-required")
                      ),
                      phoneNumber: Yup.string()
                        .matches(/^\d{10}$/, t("phone-invalid"))
                        .required(t("phone-required")),
                    })}
                    onSubmit={(values, { SetSubmiting }) => {
                      let { phoneNumber, reservationId } = values;
                      phoneNumber = contryCode + phoneNumber.substring(1);
                      this.props.login({
                        phoneNumber,
                        reservationId,
                        signInWithPhoneNumber,
                        history,
                        recaptcha: window.recaptchaVerifier,
                        toast,
                      });
                    }}
                  >
                    {(formik) => (
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <div className="row-item-equal input-container">
                            <CustomInput
                              label={t("code-rdv")}
                              // label='code-rdv'
                              id="reservationId"
                              {...formik.getFieldProps("reservationId")}
                            />
                            {formik.touched.reservationId &&
                            formik.errors.reservationId ? (
                              <div className="err-msg">
                                {formik.errors.reservationId}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="row-item-equal input-container">
                            <CustomInput
                              label={t("phone-placeholder")}
                              // label='phone-placeholder'
                              id="phoneNumber"
                              {...formik.getFieldProps("phoneNumber")}
                            />
                            {formik.touched.phoneNumber &&
                            formik.errors.phoneNumber ? (
                              <div className="err-msg">
                                {formik.errors.phoneNumber}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <SubmitBtn
                          onClick={formik.handleSubmit}
                          isLoading={this.props.fetching}
                        />
                      </form>
                    )}
                  </Formik>
                )}
              </Translation>
            </div>
          </React.Fragment>
        </div>
        {/* )} */}
        {/* </Translation> */}
      </div>
    );
  }
}

const firebaseAppAuth = firebase.auth();
const mapStateToProps = (state) => {
  return {
    fetching: state.main.fetching,
    error: state.main.error,
    email: state.main.email,
    reservationId: state.main.reservationId,
    phoneNumber: state.main.phoneNumber,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (payload) => {
      dispatch({ type: actions.SET_STATE, payload: payload });
    },
    login: (payload) => {
      dispatch({ type: actions.LOGIN, payload: payload });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebaseAuth({ firebaseAppAuth })(Login));
