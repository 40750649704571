import React, { Component } from 'react';
import logo from '../../asset/logo-white.svg'
                
class PageLogo extends Component {
    render() {
        return (
            <div className="logo-container">
                <img src={logo} alt="CIMA Yaker logo"/>
            </div>
        );
    }
}

export default PageLogo;