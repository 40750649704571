import { all, call, put, takeEvery } from "redux-saga/effects";
import actions from "../actions/actions";
import API from "../../services/api";
import { format } from "date-fns";

function* GET_WILAYAS(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetching: true,
      error: null,
    },
  });
  try {
    const response = yield call(api.getwilaya);

    const allWilayas = [];
    let alger;
    response.data.docs.map((wilaya) => {
      if (wilaya.nom !== "Alger") {
        allWilayas.push({
          label: wilaya.nom,
          id: wilaya.code,
          communes: wilaya.communes,
        });
      } else {
        alger = {
          label: wilaya.nom,
          id: wilaya.code,
          communes: wilaya.communes,
        };
      }
    });
    allWilayas.sort((a, b) => (a.label > b.label ? 1 : -1));
    allWilayas.unshift(alger);

    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: null,
        allWilayas,
      },
    });
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: error,
      },
    });
  }
}

function* ADD_APPOINTMENT(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetching: true,
      error: null,
    },
  });

  try {
    const response = yield call(api.createAppointment, payload.formData);
    
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: null,
        step: 4,
        reservationId: response.data.reservationId,
      },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: error,
      },
    });
  }
}

function* DELETE_APPOINTMENT(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetching: true,
      error: null,
    },
  });

  try {
    const response = yield call(api.deleteAppointment, payload.reservationId);
    console.log(response);
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: null,
        step: 0,
        email: "",
        phoneNumber: "",
        confirmResult: null,
        code: ["", "", "", "", "", ""],
        nom: "",
        prenom: "",
        wilaya: { id: null, label: null },
        allWilayas: null,
        allCommunes: null,
        commune: { id: null, label: null },
        /*typeExamen : null,*/
        dates: [
          { date: null, time: null },
          { date: null, time: null },
          { date: null, time: null },
        ],
        selectedFile: { path: null },
        c_delete: false,
      },
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: err,
      },
    });
  }
}
function* LOGIN(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetching: true,
      error: null,
    },
  });
  try {
    const {
      phoneNumber,
      reservationId,
      signInWithPhoneNumber,
      recaptcha,
    } = payload;
    const response = yield call(api.login, {
      phone: phoneNumber,
      reservation_id: reservationId,
    });
    const {
      data: {
        data: { token },
      },
    } = response;
    localStorage.setItem("token", token);
    yield put({
      type: actions.SET_STATE,
      payload: {
        phoneNumber,
        reservationId,
      },
    });
    let confirm = null;
    yield call(async () => {
      console.log("here");
      confirm = await signInWithPhoneNumber(phoneNumber, recaptcha);
    });
    yield put({
      type: actions.SET_STATE,
      payload: {
        confirmResult: confirm,
      },
    });
    yield call(() => {
      payload.history.push("/confirmPhoneNumber");
    });
  } catch (error) {
    yield call(() => {
      payload.toast.error(
        "Vous n'avez pas le droit de consulter cette reservation"
      );
    });
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: "Vous n'avez pas le droit de consulter cette reservation",
      },
    });
  }
}

function* EDIT_APPOINTMENT(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetching: true,
      error: null,
    },
  });
  try {
    const response = yield call(
      api.editAppointment,
      payload.reservationId,
      payload.formData
    );
    if (payload.history) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetching: false,
          error: null,
          fromLogin: false,
          edit: false,
        },
      });
      yield call(() =>
        payload.history.push(`/reservation/${payload.reservationId}`)
      );
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetching: false,
          error: null,
          step: 4,
          edit: false,
        },
      });
    }
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: error,
      },
    });
  }
}
function* GET_RESERVATION(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetching: true,
      error: null,
    },
  });
  try {
    const response = yield call(api.getReservation, payload.id);
    console.log(response.data.reservation);
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: null,
        reservation: response.data.reservation,
        phoneNumber: response.data.reservation.phone,
        nom: response.data.reservation.nom,
        prenom: response.data.reservation.prenom,
        wilaya: {
          id: response.data.reservation.commune.wilaya_id.id,
          label: response.data.reservation.commune.wilaya_id.nom,
        },
        commune: {
          id: response.data.reservation.commune.id,
          label: response.data.reservation.commune.nom,
        },
        selectedFile: { path: response.data.reservation.ordonnance_path },
        reservationId: response.data.reservation.reservation_id,
        dates: response.data.reservation.slots.map((slot) => {
          return {
            date: new Date(slot.date),
            time: new Date(
              format(new Date(slot.date), "yyyy-MM-dd") + "T" + slot.heure
            ),
          };
        }),
      },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetching: false,
        error: error,
      },
    });
  }
}

export default function* rootSaga() {
  let api = API.create();
  yield all([
    takeEvery(actions.GET_WILAYAS, GET_WILAYAS, api),
    takeEvery(actions.ADD_APPOINTMENT, ADD_APPOINTMENT, api),
    takeEvery(actions.DELETE_APPOINTMENT, DELETE_APPOINTMENT, api),
    takeEvery(actions.EDIT_APPOINTMENT, EDIT_APPOINTMENT, api),
    takeEvery(actions.GET_RESERVATION, GET_RESERVATION, api),
    takeEvery(actions.LOGIN, LOGIN, api),
  ]);
}
