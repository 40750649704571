const actions = {
  SET_STATE: "SET_STATE",
  GET_WILAYAS: "GET_WILAYAS",
  ADD_APPOINTMENT: "ADD_APPOINTMENT",
  DELETE_APPOINTMENT: "DELETE_APPOINTMENT",
  EDIT_APPOINTMENT: "EDIT_APPOINTMENT",
  GET_RESERVATION: "GET_RESERVATION",
  LOGIN: "LOGIN",
};

export default actions;
