import React, { Component } from "react";
import CustomInput from "../CustomInput";
import SubmitBtn from "./SubmitBtn";
import { connect } from "react-redux";
import actions from "../../../redux/actions/actions";
import * as Yup from "yup";
import { Formik } from "formik";
import { Translation, getI18n } from "react-i18next";
import { deviceIsMobile } from "../../../services/utils";

import {
  firebaseAppAuth,
  recaptchaVerifier,
  contryCode,
} from "../../../firebase";

class FirstStep extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      error: null,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      error: null,
    });
    window.recaptchaVerifier = recaptchaVerifier(this.recaptcha);
  }

  onSubmit = (values) => {
    this.setState({
      loading: true,
      error: null,
    });

    let { phoneNumber, email } = values;
    phoneNumber = contryCode + phoneNumber.substring(1);

    firebaseAppAuth
      .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
      .then((confirmResult) => {
        console.log({ confirmResult });
        this.props.updateState({
          phoneNumber,
          email,
          confirmResult,
          step: 1,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          error: error,
        });
        console.log("error signIn", error);
      });
  };

  phoneFormat(value) {
    if (value.charAt(0) === "+")
      return "0" + value.substring(contryCode.length);
    return value;
  }

  render() {
    return (
      <React.Fragment>
        <div ref={(ref) => (this.recaptcha = ref)}></div>
        <div
          className="first-step-container"
          style={{ width: deviceIsMobile() ? "90%" : undefined }}
        >
          <Formik
            initialValues={{
              email: this.props.email,
              phoneNumber: this.phoneFormat(this.props.phoneNumber),
            }}
            validationSchema={Yup.object({
              email: Yup.string().email(
                <Translation>
                  {(t, { i18n }) => <p>{t("email-invalid")}</p>}
                </Translation>
              ),
              phoneNumber: Yup.string()
                .matches(
                  /^\d{10}$/,
                  () =>
                    getI18n().getDataByLanguage(getI18n().language).translation[
                      "phone-invalid"
                    ]
                )
                .required(
                  <Translation>
                    {(t, { i18n }) => {
                      return <p>{t("input-required")}</p>;
                    }}
                  </Translation>
                ),
            })}
            onSubmit={this.onSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Translation>
                  {(t, { i18n }) => (
                    <React.Fragment>
                      <div className="row">
                        <div className="row-item-equal input-container">
                          <CustomInput
                            label={t("phone-label")}
                            placeholder={t("phone-placeholder")}
                            id="phoneNumber"
                            {...formik.getFieldProps("phoneNumber")}
                          />
                          {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber ? (
                            <div className="err-msg">
                              {formik.errors.phoneNumber}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="row-item-equal input-container">
                          <CustomInput
                            label={t("email-label")}
                            placeholder={t("email-placeholder")}
                            id="email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="err-msg">{formik.errors.email}</div>
                          ) : null}
                          {this.state.error && (
                            <div className="err-msg">
                              Plusieurs tentatives simultanées ont été
                              enregistrées avec ce numéro. L'envoi du code a
                              donc été bloqué.
                            </div>
                          )}
                        </div>
                      </div>

                      <SubmitBtn isLoading={this.state.loading} />
                    </React.Fragment>
                  )}
                </Translation>
              </form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.main.email,
    phoneNumber: state.main.phoneNumber,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (payload) => {
      dispatch({ type: actions.SET_STATE, payload: payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep);
