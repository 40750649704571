import React, {Component} from 'react';
import {Button,SIZE} from 'baseui/button';
import ArrowRight from 'baseui/icon/arrow-right';
import ArrowLeft from 'baseui/icon/arrow-left';
import { Translation} from 'react-i18next';
import i18n from "i18next";
import { ARABIC} from '../../pageHeader/HeaderLng'

class SubmitBtn extends Component {

    render() {
        return (
            <>
                <Button
                    size={SIZE.compact}
                    endEnhancer={() => i18n.language!==ARABIC? <ArrowRight size={24}/>: ""}
                    startEnhancer={() => i18n.language===ARABIC? <ArrowLeft size={24}/> : ""}
                    onClick={this.props.onClick}
                    overrides={{
                    BaseButton: {
                        style: {
                            borderRadius: "5px",
                        }
                    }
                }}>
                    <Translation>{(t, { i18n }) => <span>{t('next-btn')}</span>}</Translation>
                </Button>
            </>
        );
    }
}

export default SubmitBtn;