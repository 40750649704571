import React, { Component } from 'react';
import { PinCode } from "baseui/pin-code";
import { SIZE } from "baseui/input";

class CustomInput extends Component {
    render() {
        return (
            <div style={{display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center",width:"22vw"}}>
                    <PinCode overrides={{
                        Root:{
                            style:{
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                            }
                        },
                    }} values={this.props.values} size={SIZE.compact} onChange={this.props.onChange} />
            </div>

        );
    }
}

export default CustomInput;