import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import Stepper from "react-stepper-horizontal";

import CardHeader from "./CardHeader";
import FirstStep from "./firstStep/FirstStep";
import SecondStep from "./secondStep/SecondStep";
import ThirdStep from "./thirdStep/ThirdStep";
import FirstStepConfirm from "./firstStepConfirm/FirstStepConfirm";
import FourthStep from "./fourthStep/FourthStep";
import FifthStep from "./fifthStep/FifthStep";
import { deviceIsMobile } from "../../services/utils";

class MainCard extends Component {
  render() {
    return (
      <div className="main-card">
        <CardHeader />
        {this.props.step < 4 && !this.props.edit ? (
          <Translation>
            {(t, { i18n }) => {
              return (
                <div className="stepper-container">
                  <Stepper
                    activeColor="#376eaf"
                    completeColor="#376eaf"
                    titleTop={1}
                    titleFontSize={deviceIsMobile()?8:10}
                    circleFontSize={8}
                    size={18}
                    steps={[
                      { title: t("step-one") },
                      { title: t("step-two") },
                      { title: t("step-three") },
                      { title: t("step-four") },
                      { title: t("step-five") },
                    ]}
                    activeStep={this.props.step}
                  />
                </div>
              );
            }}
          </Translation>
        ) : (
          ""
        )}

        {this.props.step === 0 ? <FirstStep /> : null}
        {this.props.step === 1 ? <FirstStepConfirm /> : null}
        {this.props.step === 2 ? <SecondStep /> : null}
        {this.props.step === 3 ? <ThirdStep /> : null}
        {this.props.step === 4 ? <FourthStep /> : null}
        {this.props.step === 5 ? <FifthStep /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    step: state.main.step,
    edit: state.main.edit,
  };
};

export default connect(mapStateToProps)(MainCard);
