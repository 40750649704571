import React, { Component } from "react";
import { Select } from "baseui/select";
import { FormControl } from "baseui/form-control";

class CustomSelect extends Component {
  render() {
    return (
      <FormControl
        label={<p className="input-label">{this.props.label.toUpperCase()}</p>}
      >
        <Select
          disabled={this.props.disabled ? true : false}
          overrides={{
            ControlContainer: {
              style: {
                borderRadius: "5px",
                borderWidth: "1.5px",
                borderColor: "#eef2f7",
                backgroundColor: "#f7f9fc",
                height: "37px",
              },
            },
            ValueContainer: {
              style: {
                height: "40px",
                marginTop: "-5px",
              },
            },
          }}
          options={this.props.options ? this.props.options : []}
          placeholder={this.props.value.id ? "" : this.props.placeholder}
          onChange={this.props.onChange}
          value={this.props.value}
          id={this.props.id}
        />
      </FormControl>
    );
  }
}

export default CustomSelect;
