import React, { Component } from "react";
import InfoCard from "./InfoCard";
import { FormControl } from "baseui/form-control";
import { Datepicker } from "baseui/datepicker";
import fr from "date-fns/locale/fr";
import CustomTimePicker from "./CustomTimePicker";
import SubmitAppointment from "./SubmitAppointment";
import { connect } from "react-redux";
import actions from "../../../redux/actions/actions";
import history from "../../../services/history";
import { Translation } from "react-i18next";
import CustomBtnOutline from "../fourthStep/CustomBtnOutline";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { deviceIsMobile } from "../../../services/utils";

class ThirdStep extends Component {
  constructor(props) {
    super(props);

    let toState = {
      err_msg: "",
    };

    if (props.dates[0].date === null) {
      toState.d_disabled = [false, true, true];
    } else {
      if (props.dates[1].date === null) {
        toState.d_disabled = [false, false, true];
      } else {
        toState.d_disabled = [false, false, false];
      }
    }
    this.state = {
      ...toState,
    };
  }

  render() {
    return (
      <div
        className="third-step-container"
        style={{ width: deviceIsMobile() ? "90%" : undefined }}
      >
        <Translation>
          {(t, { i18n }) => (
            <React.Fragment>
              <div className="row">
                <div className="row-item-equal">
                  <InfoCard
                    fullName={`${this.props.nom} ${this.props.prenom}`}
                    adresse={`${this.props.wilaya.label} , ${this.props.commune.label}`}
                    files={this.props.selectedFile}
                  />
                </div>
              </div>
              {this.props.edit ? (
                ""
              ) : (
                <div className="row edit-btn-container">
                  <span onClick={this.props.toPreviousStep}>
                    {t("edit-info")}
                  </span>
                </div>
              )}

              <div className="row">
                <div className="row-item-equal">
                  <div className="date-card">
                    <h3 className="date-card-header rtl-center">
                      {t("date-choice")}
                    </h3>
                    <h3 className="date-card-header-explain rtl-center">
                      {t("date-explain")}
                    </h3>
                    <div className="row date-selctors rtl-row-reverse">
                      {this.props.dates.map((a_date, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="date-item">
                              <FormControl>
                                <Datepicker
                                  locale={fr}
                                  disabled={this.state.d_disabled[index]}
                                  value={a_date.date}
                                  formatString="dd/MM/yyyy"
                                  minDate={new Date()}
                                  onChange={({ date }) => {
                                    const updatedDates = this.props.dates;
                                    updatedDates[index].date = date;
                                    this.props.updateDates(updatedDates);
                                    if (
                                      date != null &&
                                      this.props.dates[index].time != null
                                    ) {
                                      const new_d_disabled =
                                        this.state.d_disabled;
                                      new_d_disabled[index + 1] = false;
                                      this.setState({
                                        d_disabled: new_d_disabled,
                                        err_msg: "",
                                      });
                                    } else {
                                      const new_d_disabled =
                                        this.state.d_disabled;
                                      new_d_disabled[index + 1] = true;
                                      this.setState({
                                        d_disabled: new_d_disabled,
                                        err_msg: "",
                                      });
                                    }
                                  }}
                                />
                              </FormControl>

                              <CustomTimePicker
                                label={t("time-label")}
                                disabled={this.state.d_disabled[index]}
                                value={null}
                                onChange={(date) => {
                                  const updatedDates = this.props.dates;
                                  updatedDates[index].time = date;
                                  this.props.updateDates(updatedDates);
                                  if (
                                    date != null &&
                                    this.props.dates[index].date != null
                                  ) {
                                    const new_d_disabled =
                                      this.state.d_disabled;
                                    new_d_disabled[index + 1] = false;
                                    this.setState({
                                      d_disabled: new_d_disabled,
                                      err_msg: "",
                                    });
                                  } else {
                                    const new_d_disabled =
                                      this.state.d_disabled;
                                    new_d_disabled[index + 1] = true;
                                    this.setState({
                                      d_disabled: new_d_disabled,
                                      err_msg: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                            {index >= 2 ? (
                              ""
                            ) : (
                              <div className="date-item-tinny">
                                <div className="circle">{t("or")}</div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.err_msg !== "" ? (
                <div className="err-msg text-center pt-10">
                  {" "}
                  {this.state.err_msg}
                </div>
              ) : (
                ""
              )}
              <div className="submit-appointment">
                {!this.props.edit ? (
                  ""
                ) : (
                  <div className="ml-10 mr-10">
                    <CustomBtnOutline
                      onClick={() => {
                        let values = {
                          fetching: false,
                          error: null,
                          edit: false,
                        };
                        if (this.props.fromLogin) {
                          values.fromLogin = false;
                        } else values.step = 4;
                        this.props.cancelEdit(values);

                        if (this.props.fromLogin) history.goBack();
                      }}
                      icon={<AiOutlineCloseCircle size={24} />}
                      value={t("cancel-edit")}
                    />
                  </div>
                )}

                <SubmitAppointment
                  isLoading={this.props.isLoading}
                  onClick={() => {
                    let formData = new FormData();
                    let slots = [];
                    this.props.dates.map((g_date) => {
                      if (g_date.date != null) {
                        let date = new Date();
                        const year = g_date.date.getFullYear();
                        const month = g_date.date.getMonth();
                        const day = g_date.date.getDate();
                        const hour = g_date.time.getHours();
                        const minut = g_date.time.getMinutes();
                        date.setUTCFullYear(year, month, day);
                        date.setUTCHours(hour, minut, 0);
                        slots.push({ date });
                      }
                    });

                    if (slots.length < 3) {
                      this.setState({ err_msg: t("date-choice") });
                      return;
                    }
                    const body = {
                      phone: this.props.phoneNumber,
                      nom: this.props.nom,
                      prenom: this.props.prenom,
                      slots,
                      commune: this.props.commune.id,
                      email: this.props.email,
                    };

                    formData.append("body", JSON.stringify(body));
                    for (let i = 0; i < this.props.selectedFile.length; i++) {
                      formData.append("file", this.props.selectedFile[i]);
                    }
                    if (this.props.edit) {
                      this.props.fromLogin
                        ? this.props.editAppointement(
                            this.props.reservationId,
                            formData,
                            history
                          )
                        : this.props.editAppointement(
                            this.props.reservationId,
                            formData,
                            null
                          );
                    } else {
                      this.props.createAppointment(formData);
                    }
                  }}
                  text={this.props.edit ? t("edit-appointment") : t("Confirm")}
                />
              </div>
              {this.props.error !== null && (
                <div className="err-msg text-center pt-30">
                  {this.props.error.response
                    ? this.props.error.response.data &&
                      this.props.error.response.data.msg
                    : "Une erreur est survenue! veuillez réessayer s.v.p"}
                </div>
              )}
            </React.Fragment>
          )}
        </Translation>
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  return {
    reservationId: main.reservationId,
    edit: main.edit,
    isLoading: main.fetching,
    dates: main.dates,
    email: main.email,
    phoneNumber: main.phoneNumber,
    nom: main.nom,
    prenom: main.prenom,
    wilaya: main.wilaya,
    commune: main.commune,
    /*typeExamen : main.typeExamen,*/
    selectedFile: main.selectedFile,
    fromLogin: main.fromLogin,
    error: main.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDates: (dates) => {
      dispatch({ type: "SET_STATE", payload: { dates } });
    },
    createAppointment: (formData) => {
      dispatch({ type: actions.ADD_APPOINTMENT, payload: { formData } });
    },
    toPreviousStep: () => {
      dispatch({ type: actions.SET_STATE, payload: { step: 2 } });
    },
    editAppointement: (reservationId, formData, history) => {
      dispatch({
        type: actions.EDIT_APPOINTMENT,
        payload: { reservationId, formData, history },
      });
    },
    cancelEdit: (payload) =>
      dispatch({ type: actions.SET_STATE, payload: { ...payload } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep);
