import React, { useState } from 'react';
import {IoIosGlobe} from 'react-icons/io';
import {MdKeyboardArrowDown} from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export const ARABIC = "عربية";

const HeaderLng = () =>{
    const { i18n } = useTranslation(['translatio']);
    const [lng,setLng] = useState('Français');
    const [active,setActive] = useState(false);

    const changeLanguage = code => {
        i18n.changeLanguage(code);

        let body = document.body;
        if(code=== ARABIC ){
            body.classList.add("rtl");
        }else{
            body.classList.remove("rtl");
        }
    };
    
    const onChangeLng = (event) =>{
        setActive(false);
        setLng(event.currentTarget.innerHTML);
        changeLanguage(event.currentTarget.innerHTML)
    }

    return(
            <React.Fragment>
                <div className={`header-lng-header ${active?"active-header":""}`} onClick={()=>{setActive(!active)}} >
                    <IoIosGlobe />
                    <p style={{padding:"2px"}}>{lng}</p>
                    <MdKeyboardArrowDown />
                </div>
                <div className={`header-lng-content ${active?"active-content":""}`}>
                    <ul>
                        <li className="always-fr" onClick={onChangeLng}>Français</li>
                        <li className="always-fr" onClick={onChangeLng}>English</li>
                        <li className="always-ar"  onClick={onChangeLng}>عربية</li>
                    </ul>
                </div>
            </React.Fragment>
    )
}

export default HeaderLng;