import React, { Component } from "react";
import { MdDateRange } from "react-icons/md";
import { MdAccessTime } from "react-icons/md";
import { connect } from "react-redux";

import { format } from "date-fns";

class DatesSlots extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="c-dates-slots-container">
        {this.props.dates.map((date, index) => (
          <div className="c-slot" key={index}>
            <div className="slot-row">
              <div className="slot-icon">
                <MdDateRange size={18} />
              </div>
              <div className="slot-date">
                {/* {"DATE ONE"} */}
                {format(new Date(date.date), "dd/MM/yyyy")}
              </div>
            </div>
            <div className="slot-row">
              <div className="slot-icon">
                <MdAccessTime size={18} />
              </div>
              <div className="slot-date">
                {/* {"TIME ONE"} */}
                {format(new Date(date.time), "HH:mm")}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ main }) => {
  return {
    dates: main.dates,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DatesSlots);
