import React, { Component } from "react";
import CustomBtn from "../PageContent/fourthStep/CustomBtn";
import { FiEdit } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { connect } from "react-redux";
import actions from "../../redux/actions/actions";
import ConfirmDeleteModal from "../PageContent/fourthStep/ConfirmDeleteModal";
import { MdDateRange } from "react-icons/md";
import { MdAccessTime } from "react-icons/md";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import * as moment from "moment";
import jwt from "jsonwebtoken";

import { Translation } from 'react-i18next';

import CustomBtnOutline from '../PageContent/fourthStep/CustomBtnOutline';
import CustomBtnFinal from '../PageContent/fifthStep/CustomBtnFinal';
import {IoMdCheckmark} from 'react-icons/io'

class Reservation extends Component {

    async componentDidMount() {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        const token = localStorage.getItem("token");
        const result = await jwt.verify(
            token,
            "b30e80e05d1f7cdfcbfe2a804c7c55145149be0ae78057ad04137cb003575566"
        );
        if (result.reservation.reservation_id !== id) {
            localStorage.removeItem("token");
            this.props.history.push("/login");
        } else {
            this.props.getReservation(id);
        }
    }
    render() {
        console.log(this.props.dates);
        return (
            <div className="page-content-container">
                <Translation>
                    {(t,{i18n})=>(
                    <div className="main-card">
                        <div className="card-header-content">
                            <h2> {t('mon-rdv-title')} </h2>
                        </div>
                        <hr />
                        <div className="fourth-step-container">
                            <div className="row">
                                <div className="row-item-equal">
                                    <div className="confirmation-card">
                                        <div className="info-item">
                                            <p className="input-label">{t('l-f-name')}</p>
                                            <p className="info-text">
                                                {`${this.props.reservation.nom} ${this.props.reservation.prenom}`}
                                            </p>
                                        </div>
                                        <div className="info-item">
                                            <p className="input-label">{t('adr')}</p>
                                            <p className="info-text">
                                                {`${this.props.reservation.commune.wilaya_id.nom} , ${this.props.reservation.commune.nom}`}
                                            </p>
                                        </div>
                                        {/* <div className="info-item">
                                            <p className="input-label">
                                                FICHIER JOINT
                                            </p>
                                            <p className="info-text">
                                                {this.props.reservation.ordonnance_path}
                                            </p>
                                        </div> */}
                                        
                                        {this.props.reservation.state === 2 ||
                                        this.props.reservation.state === 4 ? 
                                            <h3 className="c-date-title">
                                                {t('mon-rdv')}
                                            </h3>
                                             : (
                                            <div>
                                                <h3 className="c-date-title">
                                                    {t('mes-dates-souhaitees')}
                                                </h3>
                                                <div className="c-dates-slots-container">
                                                    {this.props.reservation.slots.map(
                                                        (slot, index) => (
                                                            <div
                                                                className="c-slot"
                                                                key={index}
                                                            >
                                                                <div className="slot-row">
                                                                    <div className="slot-icon">
                                                                        <MdDateRange
                                                                            size={18}
                                                                        />
                                                                    </div>
                                                                    <div className="slot-date">
                                                                        {moment(
                                                                            slot.date
                                                                        ).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="slot-row">
                                                                    <div className="slot-icon">
                                                                        <MdAccessTime
                                                                            size={18}
                                                                        />
                                                                    </div>
                                                                    <div className="slot-date">
                                                                        {slot.heure}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="rdv-confirm-container">
                                            <IoIosCheckmarkCircleOutline
                                                size={28}
                                            />
                                            <span className="rdv-confirm-text">
                                                {this.props.reservation.state === 2
                                                    ? t('rdv-valid') + ` ${moment(
                                                        this.props.reservation.slots.filter(
                                                            res => res.state === 2
                                                        )[0].date
                                                    ).format("DD-MM-YYYY")} - ${
                                                        this.props.reservation.slots.filter(
                                                            res => res.state === 2
                                                        )[0].heure
                                                    }`
                                                    : this.props.reservation
                                                        .state === 4
                                                    ? t("rdv-canceled")
                                                    : t('confirm-msg')
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="c-btn-row">
                                        {this.props.reservation.state === 4 ? null : (
                                            <div className="other-btn">
                                                <CustomBtnOutline
                                                    onClick={this.props.openModal}
                                                    icon={
                                                        <AiOutlineCloseCircle
                                                            size={24}
                                                        />
                                                    }
                                                    value={t('cancel-appointmetn')}
                                                />
                                                <div className="ml-10 mr-10">
                                                    <CustomBtnOutline
                                                        onClick={() => {
                                                            this.props.editAppointment();
                                                            this.props.history.push(
                                                                "/"
                                                            );
                                                        }}
                                                        icon={<FiEdit size={24} />}
                                                        value={t('edit-appointement')}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="finish-btn">
                                            <CustomBtnFinal
                                                onClick={() => {
                                                    localStorage.removeItem("token");
                                                    this.props.history.push("/login");
                                                }}
                                                icon={<IoMdCheckmark size={24}/>}
                                                value={t('quit-session')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ConfirmDeleteModal
                                onConfirm={() => {
                                    this.props.cancelAppointment(
                                        this.props.reservationId
                                    );
                                    this.props.history.push("/");
                                }}
                            />
                        </div>
                    </div>
                    )}
                </Translation>
           </div>
        );
    }
}

const mapStateToProps = ({ main }) => {
    return {
        reservationId: main.reservationId,
        nom: main.nom,
        prenom: main.prenom,
        wilaya: main.wilaya,
        commune: main.commune,
        /*typeExamen : main.typeExamen,*/
        selectedFile: main.selectedFile,
        dates: main.dates,
        reservation: main.reservation
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => {
            dispatch({ type: actions.SET_STATE, payload: { c_delete: true } });
        },
        cancelAppointment: reservationId => {
            dispatch({
                type: actions.DELETE_APPOINTMENT,
                payload: { reservationId }
            });
        },
        editAppointment: () => {
            dispatch({
                type: actions.SET_STATE,
                payload: { step: 3, edit: true, fromLogin: true }
            });
        },
        getReservation: id => {
            dispatch({
                type: actions.GET_RESERVATION,
                payload: { id }
            });
        },
        updateDates: dates => {
            dispatch({ type: "SET_STATE", payload: { dates } });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservation);
