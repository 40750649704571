import React, {Component} from 'react';
import {Button,SIZE} from 'baseui/button';

class CustomBtnFinal extends Component {
    render() {
        return (
            <div className="">
                <Button
                    type="submit"
                    size={SIZE.compact}
                    endEnhancer={() => this.props.icon}
                    onClick={this.props.onClick}
                    overrides={{
                    BaseButton: {
                        style: {
                            borderRadius: "5px",
                            backgroundColor:"#376eaf"
                        }
                    }
                }}>
                    {this.props.value}
                </Button>
            </div>
        );
    }
}

export default CustomBtnFinal;