import React, { Component } from 'react';
import {FormControl} from 'baseui/form-control';
import {Input} from 'baseui/input';

class CustomInput extends Component {
    render() {
        return (
        <FormControl label={(<p className="input-label">{this.props.label.toUpperCase()}</p>)}>
            <Input 
                overrides={{
                    Input:{
                        style:{
                            backgroundColor:"#f7f9fc",
                            borderRadius:"5px",
                            borderWidth:"1.5px",
                            borderColor:"#eef2f7"
                            }
                        },
                    InputContainer:{
                        style:{
                            borderRadius:"5px",
                            borderWidth:"1.5px",
                            borderColor:"#eef2f7",
                            height:"37px"
                        }
                    }
                }}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                value={this.props.value}
                id= {this.props.id}
            />
        </FormControl>
        );
    }
}

export default CustomInput;