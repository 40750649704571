import React, {Component} from 'react';
import {IoIosCheckmarkCircleOutline} from 'react-icons/io';
import { Translation } from 'react-i18next';


class ConfirmMsg extends Component {
    render() {
        return (
            <div className="rdv-confirm-container">
                <Translation>
                    {(t,{i18n})=>(
                        <React.Fragment>
                        <IoIosCheckmarkCircleOutline size={28}/>
                        <span className="rdv-confirm-text">
                           {t('confirm-msg')}
                        </span>   
                        </React.Fragment>                 
                    )}
                </Translation>
            </div>
        );
    }
}

export default ConfirmMsg;