import React, { Component } from 'react';
import MainCard from './MainCard';

class PageContent extends Component {
    render() {
        return (
            <div className="page-content-container">
                <MainCard/>
            </div>
        );
    }
}

export default PageContent;