import React from "react";
import "./style/style.css";
import PageHeader from "./components/pageHeader/PageHeader";
import Footer from "./components/Footer/Footer";
import { Router } from "react-router-dom";
import history from "./services/history";
import Routes from "./routes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';

toast.configure();

function App() {
  ReactGA.initialize('UA-117552162-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { t } = useTranslation("translation");
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" name="description" content={t("meta-content")} />
        <title>{t("page-title")}</title>
        <link rel="canonical" href="https://rdv.cima.dz/" />
      </Helmet>
      <PageHeader />
      {/* <PageContent /> */}
      <Router history={history}>
        <Routes />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
