import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import { BaseProvider} from 'baseui';

import { Provider } from 'react-redux'
import {createStore,applyMiddleware,compose} from 'redux';
import rootReducer from './redux/reducers/rootReducer';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './redux/saga/sagas';


import {createTheme, lightThemePrimitives} from 'baseui';

import './i18n';


const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware),
);


// create the store for redux 
export const store = createStore(rootReducer,enhancer/*applyMiddleware(sagaMiddleware),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/);

sagaMiddleware.run(rootSaga)

const engine = new Styletron();

const primitives = {
    ...lightThemePrimitives,
    primary: "#376eaf",
    primaryDarker: "#30496b",
    secondary: "#964267"
  };
  const overrides = {
    colors: {
      buttonPrimaryFill: primitives.primary,
      buttonPrimaryActive: primitives.accent300,
      buttonPrimaryHover: primitives.primaryDarker,
      
      accentColor: primitives.secondary,
    },
  };
  const theme = createTheme(lightThemePrimitives, overrides);

ReactDOM.render(
    <StyletronProvider value={engine}>
    <BaseProvider theme={theme}>
        <Provider store={store}>
          <Suspense fallback={null} >
            <App/>
          </Suspense>
        </Provider>
    </BaseProvider>
</StyletronProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
