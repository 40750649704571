import axios from "axios";
import config from "dotenv";
import { userAgent } from "./utils";

config.config();

const create = (baseURL = process.env.REACT_APP_API_URL) => {
  const httpClient = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const getwilaya = () => httpClient.get("/v1/wilayas-communes");
  const createAppointment = (data) => {
    return httpClient.post("/v1/reservations", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "user-meta-data": JSON.stringify(userAgent()),
      },
    });
  };

  const deleteAppointment = (data) =>
    httpClient.patch("/v1/reservations/" + data, { state: "CANCEL" });
  const editAppointment = (id, data) =>
    httpClient.put("/v1/reservations/" + id, data);
  const getReservation = (id) => httpClient.get("/v1/reservations/" + id);
  const login = (data) => httpClient.post("/v1/login/client", data);

  return {
    getwilaya,
    createAppointment,
    deleteAppointment,
    editAppointment,
    getReservation,
    login,
  };
};

export default { create };
