import React, { Component } from "react";
import { FormControl } from "baseui/form-control";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Paragraph3 } from "baseui/typography";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { wrappedDiv } from "../../../services/utils";
import { MdDeleteForever } from "react-icons/md";
import { deviceIsMobile } from "../../../services/utils";

const maxLength = 20;
const toMb = 1000000;

const CustomDropzone = ({ onDrop, selectedFile, removeFile }) => {
  const fileValidation = (file) => {
    if (file.size / toMb > maxLength) {
      return {
        code: "file-size",
      };
    }
    const regex = /(doc|docx|pdf|jpg|jpeg|png)$/i;
    let fileType = file.path;
    let splitStr = fileType.split(".");
    let fileExtension = splitStr[splitStr.length - 1];
    if (fileExtension.match(regex) === null) {
      return {
        code: "file-extention",
      };
    }
    return null;
  };

  const { t } = useTranslation("translation");
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    validator: fileValidation,
    onDrop,
  });

  const acceptedFileItems = selectedFile.length
    ? selectedFile.map((file, index) => (
        <dd key={index} style={{ maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: "100%",
            }}
          >
            {wrappedDiv(`▪ ${file.path}`, { maxWidth: "85%" })}

            <MdDeleteForever
              size={20}
              color="red"
              onClick={(e) => removeFile(index)}
            />
          </div>
        </dd>
      ))
    : undefined;

  const fileRejectionItems = fileRejections.length
    ? fileRejections.map(({ file, errors }, key) => (
        <dd key={key} style={{ maxWidth: "100%" }}>
          {wrappedDiv(`▪ ${file.path}`, { maxWidth: "95%" })}
          <dl style={{ maxWidth: "100%", margin: 0 }}>
            {errors.map((e) => (
              <dd key={e.code}>
                <Paragraph3
                  style={{ color: "red", marginTop: 0, fontSize: "12px" }}
                >
                  {`‣ ${t(e.code)}`}
                </Paragraph3>
              </dd>
            ))}
          </dl>
        </dd>
      ))
    : undefined;

  return (
    <section className="container" style={{ maxWidth: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "20px 0px 0px 20px",
        }}
      >
        {deviceIsMobile() ? (
          <>
            <div className="info-msg">{t("file-important-msg-1")}</div>
            <div className="info-msg">{t("file-important-msg-2")}</div>
            <div className="info-msg">{t("file-important-msg-3")}</div>
          </>
        ) : (
          <>
            <div className="info-msg">{t("file-important-msg-4")}</div>
            <div className="info-msg">{t("file-important-msg-5")}</div>
          </>
        )}
      </div>
      <section className="file-upload-container">
        <div className="file-upload-msg" {...getRootProps()}>
          <input {...getInputProps()} />

          <Translation>
            {(t, { i18n }) => (
              <React.Fragment>
                <p className="drop-msg">{t("drop-msg")}</p>
                <p className="drop-msg">{t("or")}</p>
                <p className="drop-msg">{t("browse-msg")}</p>
              </React.Fragment>
            )}
          </Translation>
        </div>
      </section>

      <aside style={{ maxWidth: "100%" }}>
        {acceptedFileItems && (
          <dl style={{ maxWidth: "100%" }}>
            <dt>{t("accepted-file")}</dt>
            {acceptedFileItems}
          </dl>
        )}
        {fileRejectionItems && (
          <dl style={{ maxWidth: "100%" }}>
            <dt>{t("rejected-file")}</dt>
            <dl style={{ maxWidth: "100%" }}>{fileRejectionItems}</dl>
          </dl>
        )}
      </aside>
    </section>
  );
};

class FileUpload extends Component {
  render() {
    return (
      <FormControl
        label={<p className="input-label">{this.props.label.toUpperCase()}</p>}
      >
        <CustomDropzone
          removeFile={this.props.removeFile}
          selectedFile={this.props.selectedFile}
          onFileError={this.props.onFileError}
          onDrop={this.props.onDrop}
        />
      </FormControl>
    );
  }
}

const mapStateToProps = ({ main }) => {
  return {
    slectedFile: main.selectedFile,
  };
};

export default connect(mapStateToProps)(FileUpload);
