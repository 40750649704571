import React, { Component } from 'react';
import {MdLocalPhone} from 'react-icons/md'

class HeaderContent extends Component {
    render() {
        return (
            <div className="header-phone-container">
                <div className="phone-info-logo">
                    <MdLocalPhone size={16}/>
                </div>
                <div className="phone-info">
                    023 78 28 28
                </div>
            </div>
        );
    }
}

export default HeaderContent;