import actions from "../actions/actions";

// action types
/*const API_CALL_REQUEST = "API_CALL_REQUEST";
const API_CALL_SUCCESS = "API_CALL_SUCCESS";
const API_CALL_FAILURE = "API_CALL_FAILURE";
const SET_STATE = "SET_STATE";*/

const initState = {
  fetching: false,
  error: null,
  step: 0,
  email: "",
  phoneNumber: "",
  confirmResult: null,
  code: ["", "", "", "", "", ""],
  nom: "",
  prenom: "",
  wilaya: { label: null, id: null },
  allWilayas: [],
  allCommunes: [],
  commune: { id: null, label: null },
  /*typeExamen : null,*/
  dates: [
    { date: null, time: null },
    { date: null, time: null },
    { date: null, time: null },
  ],
  selectedFile: [],
  c_delete: false,
  reservationId: "",
  edit: false,
  fromLogin: true,
  reservation: {
    commune: { nom: "", wilaya_id: { nom: "" } },
    slots: [],
  },
};

const mainReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default mainReducer;
