import React, { Component } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { Translation } from "react-i18next";
import { websiteURL } from "../../../firebase";
import CustomBtnFinal from "./CustomBtnFinal";
import ConfirmMsg from "./ConfirmMsg";
import { deviceIsMobile } from "../../../services/utils";

class FifthStep extends Component {
  finish = () => {
    window.location.href = websiteURL;
  };

  render() {
    return (
      <div
        className="fourth-step-container"
        style={{ width: deviceIsMobile() ? "90%" : undefined }}
      >
        <Translation>
          {(t, { i18n }) => (
            <div className="row">
              <div className="row-item-equal">
                <div className="confirmation-card">
                  <ConfirmMsg />
                </div>
                <div className="c-btn-row">
                  <div className="finish-btn submit-btn-container-final">
                    <CustomBtnFinal
                      onClick={this.finish}
                      icon={<IoMdCheckmark size={24} />}
                      value={t("complete-appointement")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Translation>
      </div>
    );
  }
}

export default FifthStep;
